import * as React from "react"


const LandingCard = (props) => {
    return (
        <div className="wrapper post-container">
            <div className="post-card-top" >
                {props.showImage && 
                    <div className="post-card-image" >
                        <picture>
                            <source
                                data-srcset={`${props.post.cover_image_url}?encode=webp&size=thumbnail`}
                                type="image/webp"
                            />
                            <img
                                data-src={`${props.post.cover_image_url}?encode=jpeg&size=thumbnail`}
                                className="lazyload"
                                alt={props.post.post_title}
                                width="280"
                                height="245"
                            />
                        </picture>
                    </div>                
                }
                <div className="post-header">
                    <a href={props.name === 'landing' ? `/${props.post.post_slug}.html` : `${props.name}/${props.post.post_slug}`}>
                        <h3 className="post-title">{props.post.post_title || props.post.post_title_primary}</h3>
                    </a>
                    tags <span>{props.post.post_slug}</span>
                    <p className="post-description">{props.post.post_excerpt || props.post.post_meta_description}</p>
                </div>
            </div>
            {props.footer}
        </div>
    )
}

export default LandingCard
