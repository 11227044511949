import * as React from "react"
import PostCategorys from "./PostCategorys"

const LandingFooter = (props) => {
    return (
            <div className="post-footer landing-footer">
                <PostCategorys info={props.postInfo}/>
                {
                    props.postInfo.price &&
                    <div>
                        <span>
                            Start from ${props.postInfo.price}
                        </span>
                    </div>
                }
            </div>
    )
}

export default LandingFooter