import React, {useState} from "react"

import Layout from "../components/layout"
import LandingCard from "../components/posts/LandingCard"
import Pagination from "../components/Pagination"
import Breadcrumbs from "../components/Breadcrumbs"
import LandingFooter from "../components/posts/LandingFooter"
import TheCategories from "../components/TheCategories"
import categories from "../content/landingCategories.json"
import filterBtn from "../images/filter.svg"
import Seo from "../components/Seo.js"

import { StructuredDataHelper } from "@helpers/structuredDataHelper.js"

const WritingServices = ({pageContext}) => {
    const structuredData = new StructuredDataHelper(pageContext)
    const {content, pages, page, categoriesLink} = pageContext
    const steps = [
        {
            name: 'Essay Writing Services',
            path: '/writing-services'
        }
    ]
    
    const category = pageContext.category || ''
    const subcategory = pageContext.subcategory || ''
    
    const [showFilter, setShowFilter] = useState(false)

    const hideFilter = () => {
        setShowFilter(!showFilter)
    }
    const itemListElements = [
        {
            position: 1,
            name: 'CustomEssayMeister',
            item: 'https://www.customessaymeister.com/'
        },
        {
            position: 2,
            name: '✔️ Writing Services',
            item: 'https://www.customessaymeister.com/writing-services'
        }
    ]

    const pageDataMain = {
        title: `Writing Services, Paper Help`,
        description: `⭐⭐⭐⭐⭐ The best online paper help and essay writing services. 🔓Confidential. ⏰On-time.`,
        keywords: 'writing service, writing services, online writing services, best online service, best writing service',
        // robots: pageContext.counter === 1 ? 'index, follow' : 'noindex, follow',
        robots: 'noindex, follow',
        micromarking: structuredData.getBreadcrumbs({ itemListElements })
    }

    const pageDataSubcategory = {
        title: `${subcategory} Writing Services`,
        description: `⭐⭐⭐⭐⭐ Get professional ${subcategory} help for money on ${category} level. 🔓Confidential. ⏰On-time.`,
        keywords: `${subcategory.toLowerCase()} help, write my ${subcategory.toLowerCase()}, pay for ${subcategory.toLowerCase()}`,
        // robots: pageContext.counter === 1 ? 'index, follow' : 'noindex, follow',
        robots: 'noindex, follow',
        micromarking: structuredData.getBreadcrumbs({ itemListElements })
    }

    const pageData = subcategory ? pageDataSubcategory : pageDataMain
    
    return (
        <>    
            <Seo {...pageData} />
            <Layout>
                <div className="blog-intro page-intro">
                    <div className="wrapper">
                        <Breadcrumbs steps={steps}/>
                        <h1 className="page-intro__title">
                            {subcategory ? 'Offers |  ' + subcategory : 'Choose Your Assignment Help'}
                            <span
                                className="filterBtn button button-primary"
                                role={'presentation'}
                                onClick={hideFilter}
                            >
                                <img src={filterBtn} alt="filter" width={30} height={30} />
                            </span>                    
                        </h1>
                    </div>
                </div>

                <div className="blog-content wrapper">
                    <div className="blog">
                        {content.map(item => <LandingCard  key={item.id} path={page} post={item} name="landing" footer={<LandingFooter postInfo={item}/>}/>)}
                    </div>
                
                    <div className="blog-categories">
                        <TheCategories 
                            categories={categories} 
                            currentCategories={category}
                            currentSubCategories={subcategory}
                            url="writing-services/category"
                            title="Assignment types"
                            showFilter={showFilter}
                            hideFilter={hideFilter}
                        />
                    </div>
                    <Pagination pageName={categoriesLink ? `writing-services/${categoriesLink}` : 'writing-services'} pages={pages} count={pageContext.counter}/>
                </div>
            </Layout>
        </>
    )
}

export default WritingServices
